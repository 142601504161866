<template>
  <div>
    <div class="sidebar-wrap">
      <div
        class="home-sidebar scrollbar-none"
        :class="{ expandedm: isShowMenu }"
      >
        <div class="home-sidebar-logo">
          <img src="@/assets/home/logo.png" />
        </div>
        <a-menu
          :default-selected-keys="[$store.state.isActMenu]"
          :selectedKeys="[$store.state.isActMenu]"
          mode="inline"
          @openChange="onOpenChange"
          :inline-collapsed="!isShowMenu"
          :open-keys="openKeys"
        >
          <a-menu-item
            class="home-sidebar-item"
            @click="linkRoute('dashboard')"
            :title="null"
            key="dashboard"
          >
            <a-tooltip placement="right" overlayClassName="white-wrap">
              <div class="_cursor" slot="title" @click="linkRoute('dashboard')">
                Dashboard
              </div>
              <i class="mdashboard"> </i>
            </a-tooltip>
            <span>Dashboard</span>
          </a-menu-item>
          <a-sub-menu class="home-sidebar-subs" key="reporting">
            <span class="msub-content home-sidebar-sub" slot="title">
              <i class="mreporting"> </i>
              <span>Reporting</span></span
            >
            <a-menu-item
              class="home-sidebar-subitem"
              @click="linkRoute('reporting')"
              key="flow-report"
            >
              Performance
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              @click="linkRoute('reporting_cps')"
              key="reporting-cps"
            >
              Transaction Details
            </a-menu-item>
            <!-- <a-menu-item
          class="home-sidebar-subitem"
          @click="linkRoute('reporting_trans_inquiry')"
          key="reporting-trans-inquiry"
        >
          Transaction Inquiries
        </a-menu-item> -->
          </a-sub-menu>
          <a-menu-item
            class="home-sidebar-item"
            :title="null"
            @click="linkRoute('campaign')"
            key="campaign"
          >
            <a-tooltip placement="right" overlayClassName="white-wrap">
              <div class="_cursor" slot="title" @click="linkRoute('campaign')">
                Campaigns
              </div>
              <i class="mcampaign"> </i>
            </a-tooltip>
            <span>Campaigns</span>
          </a-menu-item>
          <a-menu-item
            class="home-sidebar-item"
            :title="null"
            key="brands"
            @click="linkRoute('brands')"
          >
            <a-tooltip placement="right" overlayClassName="white-wrap">
              <div class="_cursor" slot="title" @click="linkRoute('brands')">
                Brands
              </div>
              <i class="mbrands"> </i>
            </a-tooltip>
            <span>Brands</span>
          </a-menu-item>
          <a-sub-menu class="home-sidebar-subs" key="tools">
            <span class="msub-content home-sidebar-sub" slot="title">
              <i class="mtools"> </i>
              <span>Tools</span></span
            >
            <a-menu-item
              class="home-sidebar-subitem"
              key="tools-deep"
              @click="linkRoute('tools_deep_link')"
            >
              Deep Link Bookmark
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="tools-links"
              @click="linkRoute('tools_custom_links')"
            >
              Custom Links
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="tools-clickapi"
              @click="linkRoute('click_report_api')"
            >
              Click Report API
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="tools-repapi"
              @click="linkRoute('tools_reporting_api')"
            >
              Reporting API
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="tools-payapi"
              @click="linkRoute('tools_payment_api')"
            >
              Payment API
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="tools-settleapi"
              @click="linkRoute('settlement_api')"
            >
              Settlement API
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="tools-statusapi"
              @click="linkRoute('tools_merstatus_api')"
            >
              Merchant Status API
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="tools-infoapi"
              @click="linkRoute('tools_merinfo_api')"
            >
              Merchant Info API
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu class="home-sidebar-subs" key="payments">
            <span class="msub-content home-sidebar-sub" slot="title">
              <i class="mpayments"> </i>
              <span>Payments</span></span
            >
            <a-menu-item
              class="home-sidebar-subitem"
              @click="linkRoute('payments')"
              key="payments-home"
            >
              Overview
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              @click="linkRoute('payments_history')"
              key="payments-history"
            >
              Payments History
            </a-menu-item>
            <!-- <a-menu-item class="home-sidebar-subitem" key="payments-bonus">
          Bonus
        </a-menu-item> -->
          </a-sub-menu>
          <a-sub-menu class="home-sidebar-subs" key="my">
            <span class="msub-content home-sidebar-sub" slot="title">
              <i class="mmyaccount"> </i>
              <span>My Account</span></span
            >
            <a-menu-item
              class="home-sidebar-subitem"
              key="my-basic"
              @click="linkRoute('my_basicinfo')"
            >
              Basic Info
            </a-menu-item>
            <!-- <a-menu-item class="home-sidebar-subitem" key="my-info">
          Company Info
        </a-menu-item> -->
            <a-menu-item
              class="home-sidebar-subitem"
              key="my-chanels"
              @click="linkRoute('my_channel')"
            >
              Channels
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="my-settings"
              @click="linkRoute('my_payment_setting')"
            >
              Payment Settings
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="my-email"
              @click="linkRoute('my_email_setting')"
            >
              Email Settings
            </a-menu-item>
            <a-menu-item
              class="home-sidebar-subitem"
              key="my-login"
              @click="linkRoute('my_login_info')"
            >
              Login Info
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
      <div
        class="menu-footer _close _cursor"
        v-if="!isShowMenu"
        @click="ShowMenu"
      >
        <img src="@/assets/menu-open.png" />
      </div>
      <div class="menu-footer _open _cursor" v-else @click="hideMenu">
        <img src="@/assets/menu-close.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collection: {
      type: String,
    },
  },
  data() {
    return {
      isShowMenu: false,
      rootSubmenuKeys: ["reporting", "payments", "my", "tools"],
      openKeys: [],
    };
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    linkRoute(routeName) {
      const { name } = this.$route;
      if (name === routeName) {
        console.log("same route");
        return;
      }
      this.$router.push({
        name: routeName,
      });
    },
    ShowMenu() {
      this.isShowMenu = true;
    },
    hideMenu() {
      this.isShowMenu = false;
    },
  },
};
</script>

<style lang="less" scoped>
.sit-wrap {
  height: 7.1429rem;
}
.sidebar-wrap {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  .menu-footer {
    height: 7.1429rem;
    display: flex;
    align-items: center;
    padding: 0 2.2857rem;
    img {
      height: 2.8571rem;
      width: 2.8571rem;
    }
  }
  ._close {
    justify-content: center;
  }
  ._open {
    border-top: 1px solid #f5f5f5;
    justify-content: flex-end;
  }
}
.home-sidebar {
  width: 7.2857rem;
  flex-shrink: 0;
  background: #fff;
  padding: 2rem 2.2857rem 0;
  overflow-y: auto;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  .mdashboard {
    background: url(~@/assets/home/icon-dashboard.svg) center/contain no-repeat;
  }
  .mreporting {
    background: url(~@/assets/home/icon-reporting.svg) center/contain no-repeat;
  }
  .mcampaign {
    background: url(~@/assets/home/icon-campaign.svg) center/contain no-repeat;
  }
  .mbrands {
    background: url(~@/assets/home/icon-brands.svg) center/contain no-repeat;
  }
  .mtools {
    background: url(~@/assets/home/icon-tools.svg) center/contain no-repeat;
  }
  .mpayments {
    background: url(~@/assets/home/icon-payments.svg) center/contain no-repeat;
  }
  .mmyaccount {
    background: url(~@/assets/home/icon-myaccount.svg) center/contain no-repeat;
  }
  .ant-menu-item-selected {
    .mdashboard {
      background: url(~@/assets/home/icon-dashboard-select.svg) center/contain
        no-repeat;
    }
    .mreporting {
      background: url(~@/assets/home/icon-reporting-select.svg) center/contain
        no-repeat;
    }
    .mcampaign {
      background: url(~@/assets/home/icon-campaign-select.svg) center/contain
        no-repeat;
    }
    .mbrands {
      background: url(~@/assets/home/icon-brands-select.svg) center/contain
        no-repeat;
    }
    .mtools {
      background: url(~@/assets/home/icon-tools-select.svg) center/contain
        no-repeat;
    }
    .mpayments {
      background: url(~@/assets/home/icon-payments-select.svg) center/contain
        no-repeat;
    }
    .mmyaccount {
      background: url(~@/assets/home/icon-myaccount-select.svg) center/contain
        no-repeat;
    }
  }
  .msub-content {
    display: flex;
    align-items: center;
  }
  &.expandedm {
    width: 17.5714rem;
    .home-sidebar-item {
      padding-left: 8px !important;
      width: auto;
      justify-content: inherit;
      > i {
        margin-right: 6px;
      }
      > span {
        display: inline-block !important;
      }
    }
    .ant-menu-item-selected {
      width: auto;
    }
    .home-sidebar-subs {
      .home-sidebar-sub {
        > span {
          display: inline-block !important;
        }
      }
    }
    /deep/ .ant-menu-item:hover {
      width: auto;
    }
    /deep/ .ant-menu-submenu-selected {
      width: auto;
    }
    /deep/ .ant-menu-sub {
      display: block;
    }
  }
  &-logo {
    margin-bottom: 1.1429rem;
    img {
      width: 2.8571rem;
      height: auto;
    }
  }
  .home-sidebar-subs {
    .home-sidebar-sub {
      font-size: 1.1429rem;
      > span {
        display: none;
      }
    }
  }
  .home-sidebar-item {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8571rem;
    height: 2.8571rem;
    > i {
      width: 1.5714rem;
      height: 1.5714rem;
      flex-shrink: 0;
    }
    > span {
      display: none;
      font-size: 1.1429rem;
    }
  }
  .home-sidebar-sub {
    > i {
      width: 1.5714rem;
      height: 1.5714rem;
      margin-right: 6px;
      flex-shrink: 0;
    }
  }
  &-subitem {
    padding-left: 1.1429rem !important;
    font-size: 1.1429rem;
  }
}
/deep/ .ant-menu-sub {
  display: none;
}
/deep/ .ant-menu-inline-collapsed {
  width: 5.2857rem;
  border: none;
}
/deep/ .ant-menu-item-selected,
.ant-menu-submenu-selected span {
  color: #3762d6;
  font-family: "prefer-bold";
}
/deep/ .ant-menu-submenu-selected .ant-menu-submenu-title,
/deep/ .ant-menu-submenu-title:hover {
  color: #3762d6;
  background-color: #f6f9ff !important;
  border-radius: 8px;
  .mdashboard {
    background: url(~@/assets/home/icon-dashboard-select.svg) center/contain
      no-repeat;
  }
  .mreporting {
    background: url(~@/assets/home/icon-reporting-select.svg) center/contain
      no-repeat;
  }
  .mcampaign {
    background: url(~@/assets/home/icon-campaign-select.svg) center/contain
      no-repeat;
  }
  .mbrands {
    background: url(~@/assets/home/icon-brands-select.svg) center/contain
      no-repeat;
  }
  .mtools {
    background: url(~@/assets/home/icon-tools-select.svg) center/contain
      no-repeat;
  }
  .mpayments {
    background: url(~@/assets/home/icon-payments-select.svg) center/contain
      no-repeat;
  }
  .mmyaccount {
    background: url(~@/assets/home/icon-myaccount-select.svg) center/contain
      no-repeat;
  }
  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background: linear-gradient(to right, #3762d6, #3762d6);
  }
}
/deep/ .ant-menu-submenu-selected {
  width: 2.8571rem;
}
/deep/ .ant-menu-item:hover {
  color: #3762d6;
  background-color: #f6f9ff !important;
  border-radius: 8px;
  .mdashboard {
    background: url(~@/assets/home/icon-dashboard-select.svg) center/contain
      no-repeat;
  }
  .mreporting {
    background: url(~@/assets/home/icon-reporting-select.svg) center/contain
      no-repeat;
  }
  .mcampaign {
    background: url(~@/assets/home/icon-campaign-select.svg) center/contain
      no-repeat;
  }
  .mbrands {
    background: url(~@/assets/home/icon-brands-select.svg) center/contain
      no-repeat;
  }
  .mtools {
    background: url(~@/assets/home/icon-tools-select.svg) center/contain
      no-repeat;
  }
  .mpayments {
    background: url(~@/assets/home/icon-payments-select.svg) center/contain
      no-repeat;
  }
  .mmyaccount {
    background: url(~@/assets/home/icon-myaccount-select.svg) center/contain
      no-repeat;
  }
  width: 2.8571rem;
}
/deep/
  .ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 0 8px !important;
  display: flex;
  align-items: center;
  width: 2.8571rem;
  height: 2.8571rem;
}
/deep/ .ant-menu-item-selected {
  color: #3762d6;
  background-color: #f6f9ff !important;
  border-radius: 8px;
  width: 2.8571rem;
}
/deep/ .ant-menu-submenu-title {
  padding-left: 8px !important;
}
/deep/ .ant-menu-inline .ant-menu-item::after {
  content: none;
}
/deep/ .ant-menu-inline {
  border: none;
}
/deep/ .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}
/deep/ .footer-pagination {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 32px;
}
</style>
