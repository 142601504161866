<template>
  <div class="home">
    <Loading v-if="isLoading"></Loading>
    <SideMenu />
    <div class="home-content">
      <router-view v-if="!isLoading"></router-view>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request";
import SideMenu from "@/components/public/SideMenu.vue";
import apis from "@/api/const";
// import NavHeader from "../components/NavHeader.vue";
// import NavFooter from "../components/NavFooter.vue";
// import Menu from "../components/Menu.vue";
// import ChatModal from "../components/ChatModal.vue";
// import Guide from "../components/Guide.vue";

export default {
  name: "Home",
  components: {
    // Guide,
    // ChatModal,
    // NavHeader,
    // NavFooter,
    // Menu,
    SideMenu,
  },
  data() {
    return {
      loading: 1,
      chatMediumId: null,
      chatUserId: null,
      isShowMenu: false,
      rootSubmenuKeys: ["sub1", "sub2", "sub4"],
      openKeys: [],
    };
  },
  computed: {
    isLoading() {
      return this.loading > 0;
    },
  },
  methods: {
    ShowMenu() {
      this.isShowMenu = true;
    },
    hideMenu() {
      this.isShowMenu = false;
    },
  },
  watch: {},
  async created() {
    const [couRes, siteRes, userRes] = await Promise.all([
      axios.get(apis.common.country_list, {
        params: {},
      }),
      axios.get(apis.common.site_info, {
        params: {},
      }),
      axios.get(apis.my.user_info, {
        params: {},
      }),
    ]);

    if (couRes.code === "0200") {
      this.$store.commit("setCountryList", couRes.payload);
    }
    if (siteRes.code === "0200") {
      this.$store.commit("setSiteInfo", siteRes.payload);
    }
    if (userRes.code === "0200") {
      this.$store.commit("setUserInfo", userRes.payload);
    }
    this.$nextTick(() => {
      this.loading--;
    });
  },
  async mounted() {},
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  background: #f9fbfe;
  &-content {
    flex-shrink: 0;
    background: #f9fbfe;
    min-height: 100vh;
    min-width: 86.2381rem;
    flex: 1;
    padding: 1.4286rem 1.4286rem;
  }
}
</style>
